import React, {Component} from "react";
import hameyie from "../../../static/images/m3-min.png";
import hameyie2 from "../../../static/images/me-min.png";
import config from "../../../data/SiteConfig";
import "./bio.scss";
import {Link} from "gatsby";

class Bio extends Component {
    constructor(props) {
        super(props);
        this.showFull = this.props.showFull || false;
        this.bioPic = (new Date().getMinutes() % 2) === 0 ? hameyie : hameyie2;
    }

    getImagePath(svg) {
        return `/images/png/${svg}.png`
    }

    getLinkElements() {
        const {userLinks} = config;
        return userLinks.map(link => (
          <li className="list-inline-item social" key={link.label}>
            <a className="no-shadow" href={link.url} aria-label={link.label}>
              <img alt={link.label} src={this.getImagePath(link.svgIcon)} />
            </a>
          </li>
        ));
    }

    render() {
        return (
          <>
            <div className="mx-auto text-center">
              <div className="row bio-container">
                <div className="col-4 avatar-container">
                  <img className="rounded-circle avatar-main" src={this.bioPic} alt="Boguste Hameyie" />
                </div>
                <div className="col-8 container summary">
                  <div className="summary-content">

                    <h1>
                      Hey! I’m
                      <b>Boguste</b>
                    </h1>
                    <p className="summary-text">
                      {config.userDescription}
                    </p>
                    {this.showFull && (
                      <p className="summary-text">
                        I used to blog on <a href="https://bhameyie.wordpress.com"> Wordpress </a> and also on <a href="http://47ron.in/blog/"> 47Ron.in </a>. My latest content can now be found <Link to="/blog">here</Link> .
                      </p>
)}
                  </div>
                  <ul className="list-inline">{this.getLinkElements()}</ul>
                </div>
              </div>
            </div>
          </>
        );
    }
}

export default Bio;
